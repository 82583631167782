import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Navbar, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../icons/icons';
import LocalStorageUtils from '../../utils/local-storage';
import { PAMMState } from '../../redux/reducers/rootReducer';
import { Investor } from '../../types/investorTypes';
import fetchInvestorDetailsAsync from '../../redux/actions/investor/fetchInvestorDetails';
import { AccountType } from '../../types/authTypes';
import { fetchSharedSettingsAsync } from '../../redux/actions/shared/fetchConfig';

const BottomBarMobile: React.FC<any> = ({ user, location }): JSX.Element => {
  const dispatch = useDispatch();

  const api: string = useSelector((state: PAMMState) => state.configReducer.api);
  const customColor = useSelector((state: PAMMState) => state.configReducer.customColor);
  // PAMM-2341 TODO
  // const showRequestTab = LocalStorageUtils.getValueFromLocalStorage('showRequestTab');
  const currentAddress = location.pathname;
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  // PAMM-2341 TODO
  const showRequestTab: string = useSelector((state: PAMMState) => state.configReducer.requestTab.toString());

  const shouldDashboardButtonLightUp = (): boolean => {
    return currentAddress.includes('dashboard') ||
      currentAddress.includes('commissions') ||
      currentAddress.includes('closed-order') ||
      currentAddress.includes('history') ||
      currentAddress.includes('change-balance') ||
      currentAddress.includes('change-credit') ||
      currentAddress.includes('balance-operation');
  };

  const onlyTwoButtonsActive = user.AccountOptions.MasterList === 'Hide' && showRequestTab === 'false';

  const linkedId = LocalStorageUtils.getValueFromLocalStorage('linkedIdMobile') || user.Id;

  React.useEffect(() => {

    if (user?.Id && user?.AccountType === AccountType.Investor) {
      let investorId = 0;

      if (user?.AccountType === AccountType.Investor) {
        if (user?.AccountInfo?.LinkToken) {
          investorId = Number(linkedId);
        }
        else {
          investorId = user?.Id;
        }
      }

      dispatch(fetchInvestorDetailsAsync(api, investorId));
    }
  }, [dispatch, api, user.Id, linkedId]);

  // PAMM-2341 TODO
  React.useEffect(() => {
    dispatch(fetchSharedSettingsAsync(api));
  }, [dispatch, api, showRequestTab]);

  const buttonStyle = {
    display: 'flex',
    width: '2.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '.3rem',
    marginTop: '.3rem',
  } as React.CSSProperties;

  const activeButtonStyle = {
    ...buttonStyle,
    color: customColor,
  } as React.CSSProperties;
  
  const textStyle = {
    color: 'black',
    fontSize: 12,
  } as React.CSSProperties;

  const activeTextStyle = {
    ...textStyle,
    color: customColor,
    textDecoration: 'none',
    outline: 'none',
  } as React.CSSProperties;

  return (
    <Navbar
      fixed="bottom"
      className={`flex ${onlyTwoButtonsActive ? 'justify-content-around' : 'justify-content-between'}`}
      style={{ paddingBottom: 22, backgroundColor: '#EFEFEF' }}
    >
      <Link to="/dashboard">
        <Col>
          <div
            style={shouldDashboardButtonLightUp() ? activeButtonStyle : buttonStyle}
            className="mx-auto"
          >
            {Icons.ChartAreaIcon(shouldDashboardButtonLightUp() ? customColor : '#343a40', '1x')}
          </div>
          <div style={shouldDashboardButtonLightUp() ? activeTextStyle : textStyle} className="text-center">
            <FormattedMessage id="bottomBar.dashboard" />
          </div>
        </Col>
      </Link>
      {/* <Link to="/investors">
        <Col>
          <div style={buttonStyle} className="mx-auto">
            {Icons.UserIcon('#343a40', '1x')}
          </div>
          <div style={textStyle} className="text-center">
            <FormattedMessage id="bottomBar.investors" />
          </div>
        </Col>
      </Link> */}
      {investor.AccountOptions.MasterList === 'Show' && user.AccountType === 'investor'
      ? (
        <Link to="/money_managers">
          <Col>
            <div
              style={currentAddress.includes('/money_managers') || currentAddress.includes('/bio') ? activeButtonStyle : buttonStyle}
              className="mx-auto"
            >
              {Icons.UsersIcon(currentAddress.includes('/money_managers') || currentAddress.includes('/bio') ? customColor : '#343a40', '1x')}
            </div>
            <div style={currentAddress === '/money_managers' ? activeTextStyle : textStyle} className="text-center">
              <FormattedMessage id="bottomBar.masters" />
            </div>
          </Col>
        </Link>
      ) : null}
      {showRequestTab !== 'false'
      ? (
        <Link to={{ pathname: '/requests', key: Math.random().toString()}}>
          <Col>
            <div
              style={currentAddress.includes('/requests') ? activeButtonStyle : buttonStyle}
              className="mx-auto"
            >
              {Icons.ExchangeIcon(currentAddress.includes('/requests') ? customColor : '#343a40', '1x')}
            </div>
            <div style={currentAddress === '/requests' ? activeTextStyle : textStyle} className="text-center">
              <FormattedMessage id="bottomBar.requests" />
            </div>
          </Col>
        </Link>
      ) : null}
      <Link to="/profile">
        <Col>
          <div
            style={currentAddress === '/profile' ? activeButtonStyle : buttonStyle}
            className="mx-auto"
          >
            {Icons.CogIcon(currentAddress === '/profile' ? customColor : '#343a40', '1x')}
          </div>
          <div style={currentAddress === '/profile' ? activeTextStyle : textStyle} className="text-center">
            <FormattedMessage id="bottomBar.profile" />
          </div>
        </Col>
      </Link>
    </Navbar>
  );
};

export default withRouter(BottomBarMobile);
